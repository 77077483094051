import React, {FC, Fragment, useState} from 'react';
import {Button, Checkbox, Confirm, Image, Label, List, Table} from 'semantic-ui-react';
import FirebaseFirestoreService from '../../../services/FirebaseFirestoreService';
import './FoodView.css';
import Food from '../../../model/food';
import DefaultImage from '../../../assets/image-default.png';
import ModalFoodItemUpdate from '../../layouts/ModalFoodItemUpdate';
import {numberWithCommas} from '../../../helper/utils';
import {useTranslation} from 'react-i18next';
import {deleteFile} from '../../../services/FirebaseStorageService';
import OptionFoodForm from "../../layouts/OptionFoodForm";
import { v4 as uuidv4 } from "uuid";

interface FoodItemRowProps {
  id_restaurant: string;
  foodItem: Food;
  listCate: any[];
}

const FoodItemRow: FC<FoodItemRowProps> = (props) => {
  const { t } = useTranslation();
  const [modalEditOpen, changeEditModalOpen] = useState(false);
  const [openDelete, changeOpenDelete] = useState(false);

  const onDeleteFood = async (foodId: string, imagePath: string) => {
    await deleteFile(imagePath).then(() => { }).catch((e) => { console.log(e) });
    await FirebaseFirestoreService.deleteFoodItem(props.id_restaurant, foodId);
  }

  return (
    <Table.Row>
      <Table.Cell data-label={t("Food_Items.table.name")}>{props.foodItem.name}</Table.Cell>
      <Table.Cell id="hiddenMobile" data-label={t("Food_Items.table.image")}>
        {props.foodItem.image ? (
          <Image rounded size="tiny" src={props.foodItem.image}  onError={(error: any) => {
            const src = error.target.src;
            error.target.src = DefaultImage;
            setTimeout(() => {
              error.target.src = src;
            }, 5000);
          }}/>
        ) : (
          <Image rounded size="tiny" src={DefaultImage} />
        )}
      </Table.Cell>
      <Table.Cell id="hiddenMobile" data-label={t("Food_Items.table.description")}>
        {props.foodItem.description}
      </Table.Cell>
     
      <Table.Cell data-label={t("Food_Items.table.price")}>{numberWithCommas(props.foodItem.price)}</Table.Cell>
      <Table.Cell id="hiddenMobile" data-label={t("Food_Items.table.category")}>
        <List>
          {props.foodItem.category_id.map((cate: any) => (
            <List.Item key={cate.value}>
              <Label
                className="label-no-wrap"
                content={props.listCate.find((ele) => ele.id === cate.value)?.name}
                icon="tag"
              />
            </List.Item>
          ))}
        </List>
      </Table.Cell>
      <Table.Cell id="hiddenMobile" data-label={t("Food_Items.table.option")}>
        <List>
          {props.foodItem.options &&
            props.foodItem.options.map((opt: any) => (
              <List.Item key={opt.value}>
                <Label className="label-no-wrap" content={opt.label} icon="spoon" />
              </List.Item>
            ))}
        </List>
      </Table.Cell>
      <Table.Cell id="hiddenMobile" data-label={t("Food_Items.table.topping")}>
        <List>
          {props.foodItem.toppings &&
            props.foodItem.toppings.map((opt: any) => (
              <List.Item key={opt.value}>
                <Label
                  className="label-no-wrap"
                  content={opt.label}
                  icon="spoon"
                />
              </List.Item>
            ))}
        </List>
      </Table.Cell>
      <Table.Cell data-label={t("Food_Items.table.active")} collapsing>
        <Checkbox
          toggle
          checked={props.foodItem.is_active}
          onChange={() =>
            FirebaseFirestoreService.changeStatusFood(
              props.id_restaurant,
              props.foodItem.id,
              props.foodItem.is_active
            )
          }
        />
      </Table.Cell>
      <Table.Cell collapsing>
        <Fragment>
          <div className="cell-group-btn">
            <Button icon="edit" onClick={() => changeEditModalOpen(true)} />
            <Button icon='delete' onClick={() => changeOpenDelete(true)} />
            <Confirm
              header="Tin nhắn"
              content="Vui lòng xác nhận xóa món ăn này!"
              open={openDelete}
              size='mini'
              onCancel={() => { changeOpenDelete(false); }}
              onConfirm={() => { onDeleteFood(props.foodItem.id, props.foodItem.image); changeOpenDelete(false); }}
            />
          </div>
          {modalEditOpen && (
            <ModalFoodItemUpdate
              listCate={props.listCate}
              id_restaurant={props.id_restaurant}
              openModal={modalEditOpen}
              foodItem={props.foodItem}
              setOpenModal={changeEditModalOpen}
            />
          )}
        </Fragment>
      </Table.Cell>
    </Table.Row>
  );
};
export default FoodItemRow;
