import { StylesConfig } from "react-select";

export interface ColourOption {
    readonly value: number;
    readonly label: string;
    readonly isChild?: boolean;
}


export const colourStyles: StylesConfig<ColourOption, true> = {
    // control: (styles) => ({ ...styles, backgroundColor: 'white' }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        //   const color = chroma(data.color);
        return {
            ...styles,
            paddingLeft: data.isChild ? '2rem' : '',
            ':before': {
                content: data.isChild ? '" - "' : '""'
            }
        };
    },
};