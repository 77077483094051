import React, { FC, Fragment } from "react";
import { Card, Dropdown, DropdownProps, Header, Icon } from "semantic-ui-react";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { useAppDispatch } from "../../redux/store";
import { selectRestaurantId, selectuser, setRestaurantId } from "../../redux/userSlice";
import useRestaurant from "../../hooks/useRestaurant";


const DropdownRestaurants: FC<any> = (props) => {
    const { t } = useTranslation();
    const dispatchReduxToolkit = useAppDispatch();
    const resId = useSelector(selectRestaurantId);
    const user = useSelector(selectuser);
    const [listRest] = useRestaurant(user);

    const resIdOnchange = (e: any, data: DropdownProps) => {
        e.preventDefault();
        const selected = data.value ? data.value.toString() : '';
        dispatchReduxToolkit(setRestaurantId({ id: selected }));
    }

    return (
        <Fragment>
            {(user.permission === 0 || user?.restaurants?.length > 1) ?
                <Card className="container-header">
                    <Header as='h3'>
                        <Icon name='utensils' />
                        <Header.Content>{t("Restaurant.name")}</Header.Content>
                    </Header>
                    <Dropdown
                        placeholder={t("Restaurant.select")}
                        fluid
                        selection
                        value={resId}
                        options={listRest}
                        onChange={resIdOnchange}
                    />
                </Card>
                : <Fragment></Fragment>
            }
        </Fragment>
    )
}
export default DropdownRestaurants;