import React, { FC, Fragment, useEffect, useState } from "react";
import {
    Button,
    Image,
    Form,
    Modal,
    Input,
    Label,
    Progress,
    Dropdown,
    DropdownProps,
    Table,
    Checkbox
} from "semantic-ui-react";
import FirebaseFirestoreService from "../../services/FirebaseFirestoreService";
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { deleteFile, uploadFile } from "../../services/FirebaseStorageService";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../FireBaseConf";
import { deleteField } from "firebase/firestore";
import { v4 as uuidv4 } from 'uuid';
import { colourStyles } from "./SelectStyleTree";
import Utils from "../../helper/utils";
import {listUnit, FIRST_BLOCK_TIME_DEFAULT} from "../../helper/constants";
import { convertImageUrl } from "../../helper/resize";
import { Col, Collapse, Row } from "antd";
interface ModalFoodItemProps {
    id_restaurant: string,
    openModal: boolean,
    foodItem: any,
    listCate: any[],
    setOpenModal: Function
}

const ModalFoodItemUpdate: FC<ModalFoodItemProps> = (props) => {
    const { t } = useTranslation();
    
    const cateValueMapping = props.foodItem.category_id.map((item: any) => {
        return { value: item.value, label: props.listCate.find((ele) => ele.id === item.value)?.name }
    })
    const [name, setName] = useState(props.foodItem.name);
    const [tag, setTag] = useState(props.foodItem.tag || "");
    const [price, setPrice] = useState<string>(props.foodItem.price.toString());
    const [desc, setDesc] = useState<string>(props.foodItem.description);
    const [printName, setPrintName] = useState<string>(props.foodItem?.printName || "");
    const [priceTax, setPriceTax] = useState<string>(props.foodItem.pricePostTax.toString());
    const [secondPrice, setSecondPrice] = useState<string>( props.foodItem.price2 ? props.foodItem.price2.toString() : "0" );
    const [firstBlockTimeNet, setFirstBlockTimeNet] = useState<number>( props.foodItem.firstBlockTime ? props.foodItem.firstBlockTime : 0 );
    const [secondBlockTimeNet, setSecondBlockTimeNet] = useState<number>( props.foodItem.secondBlockTime ? props.foodItem.secondBlockTime : 0 );
    const [secondPriceTax, setSecondPriceTax] = useState<string>( props.foodItem.pricePostTax2 ? props.foodItem.pricePostTax2.toString() : "0" );
    const [taxRate, setTaxRate] = useState<string>('10');
    const [listCate, setListCate] = useState<any[]>([]);
    const [isError, setErrorChange] = useState(false);
    const [value, setValue] = useState<any[]>(cateValueMapping);
    const [file, setFile] = useState<any>();
    const [percent, setPercent] = useState(0);
    const [imagePath, setImagePath] = useState<string>(props.foodItem.image);
    const [valueOptions, setValueOptions] = useState<any[]>(props.foodItem.options ?? []);
    const [valueTopping, setValueTopping] = useState<any[]>(props.foodItem.toppings ?? []);
    const [listOptions, setListOptions] = useState<any[]>([]);
    const [listTopping, setListTopping] = useState<any[]>([]);
    const [taxSelection, taxSelectionOnchange] = useState<any[]>([{
        key: '10',
        text: '% 10',
        value: '10'
    }]);
    const [intergrateInventory, onIntergrateInventory] = useState(props.foodItem?.is_material !== undefined);
    const [inventoryItem, setInventoryItem] = useState<any>();
    const [isBlockTime, setIsBlockTime] = useState<any>( props.foodItem.isBlockTime ? props.foodItem.isBlockTime : false );
    const [restaurant, setRestaurant] = useState<any>([]);
    const [languageValue, setLanguageValue] = useState<any>(
      props.foodItem.nameLanguages ? props.foodItem.nameLanguages : {}
    );

    useEffect(() => {
        if (props.id_restaurant === "") {
            return;
        }

        const fecthCate = async () => {
            const unsubscribe = await FirebaseFirestoreService.getCateTree(props.id_restaurant);

            setListCate(unsubscribe);
        }

        const fetchRestaurantDetail = async () => {
            const res = await FirebaseFirestoreService.getRestaurantDetail( props.id_restaurant );
            setRestaurant(res.data());
        };

        fetchRestaurantDetail();
        fecthCate();

    }, [props.id_restaurant, setListCate, value]);

    useEffect(() => {
        if (props.id_restaurant === "") {
            return;
        }
        const unsubscribe = FirebaseFirestoreService.streamOptionsListItemsOnActive(props.id_restaurant,
            (querySnapshot: any) => {
                const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
                    label: doc.data().name,
                    value: doc.id
                }));
                setListOptions(updatedGroceryItems);
            },
            (error: any) => {
            }
        );
        return unsubscribe;
    }, [props.id_restaurant, setListOptions, valueOptions]);

    useEffect(() => {
        if (props.id_restaurant === "") {
          return;
        }
        const unsubscribe = FirebaseFirestoreService.streamToppingListItemsOnActive(
          props.id_restaurant,
          (querySnapshot: any) => {
            const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
              label: doc.data().name,
              value: doc.id,
            }));
            setListTopping(updatedGroceryItems);
          },
          (error: any) => {}
        );
        return unsubscribe;
      }, [props.id_restaurant, setListTopping, valueTopping]);

    const handleChange = (newValue: any, data: any) => {
        const valueSelected = newValue.map((item: any) => {
            return {
                value: item.value
            }
        })
        setValue(valueSelected);
    }

    const handleChangeOptions = (newValue: any, data: any) => {
        setValueOptions(newValue);
    }
    const handleChangeTopping = (newValue: any, data: any) => {
        setValueTopping(newValue);
      };

    const clearState = () => {
        props.setOpenModal(false);
    }

    const handleChangeUpload = (e: any) => {
        setImagePath("");
        setFile(e.target.files[0]);
    }

    const taxOnchange = (e: any, data: DropdownProps) => {
        e.preventDefault();
        const selected = data.value ? data.value.toString() : "";
        setPrice('');
        setPriceTax('');

        setTaxRate(selected);
    };

    const cateGoryQuery = () => {
        return value.map((item: any) => {
            return {
                value: item.value
            }
        })
    }

    const onSubmit = async () => {

        setErrorChange(false);

        if (props.id_restaurant === "" || name === "" || price === "" || priceTax == "" || secondPrice == "" || secondPriceTax == "") {
            setErrorChange(true);
            return;
        }

        if (Utils.validJapanese(tag) > 10) {
            setErrorChange(true);
            return;
        }

        //////////////////////////////////////////////

        const isItem = props?.foodItem?.is_material !== undefined && props?.foodItem?.is_material === false;

        let material = [];
        if (intergrateInventory) {
            let valid = true;
            if (!inventoryItem || !inventoryItem.data || !inventoryItem.type) {
                valid = false;
            }
            if (inventoryItem.type === "material") {
                material = inventoryItem.data.map((item:any) => {
                    if (!item.quantity_required) {
                        valid = false;
                    }
                    return {
                        id: item.value,
                        name: item.name,
                        unit: item.unit,
                        quantity_required: parseFloat(item.quantity_required)
                    }
                })

                if (isItem) {
                    await FirebaseFirestoreService.deleteItemInventory(props.id_restaurant, props?.foodItem?.material[0].id);
                }

            }
            if (inventoryItem.type === "items") {
                if (inventoryItem.data.price === '' || inventoryItem.data.min_quantity === '' || inventoryItem.data.unit === '') {
                    valid = false;
                }
                const newItem = {
                    name: name,
                    price: parseFloat(inventoryItem.data.price),
                    unit: inventoryItem.data.unit,
                    min_quantity: parseFloat(inventoryItem.data.min_quantity),
                    quantity_required: 1
                }
                if (isItem) {
                    const idItem = props?.foodItem?.material[0].id;
                    material = [{
                        ...newItem,
                        id: idItem
                    }]
                    await FirebaseFirestoreService.updateItemInventory(props.id_restaurant, idItem, newItem);
                } else {
                    const idItem = await FirebaseFirestoreService.addItemInventory(props.id_restaurant, newItem);
                    material = [{
                        ...newItem,
                        id: idItem
                    }]
                }
            }
            if (!valid){
                setErrorChange(true);
                return;
            }
        } else {
            if (isItem) {
                await FirebaseFirestoreService.deleteItemInventory(props.id_restaurant, props?.foodItem?.material[0].id);
            }
        }

        const data = intergrateInventory ? {
            name: name,
            description: desc,
            tag: tag,
            price: parseInt(price),
            price2: parseInt(secondPrice),
            pricePostTax: parseInt(priceTax),
            pricePostTax2: parseInt(secondPriceTax),
            isBlockTime: isBlockTime,
            firstBlockTime: firstBlockTimeNet,
            secondBlockTime: secondBlockTimeNet,
            image: imagePath,
            printName: printName,
            category_id: cateGoryQuery(),
            options: valueOptions,
            toppings: valueTopping,
            is_material: inventoryItem?.type === "material",
            material: material,
            nameLanguages: languageValue,
        } : {
            name: name,
            description: desc,
            tag: tag,
            price: parseInt(price),
            price2: parseInt(secondPrice),
            pricePostTax: parseInt(priceTax),
            pricePostTax2: parseInt(secondPriceTax),
            isBlockTime: isBlockTime,
            firstBlockTime: firstBlockTimeNet,
            secondBlockTime: secondBlockTimeNet,
            image: imagePath,
            printName: printName,
            category_id: cateGoryQuery(),
            options: valueOptions,
            toppings: valueTopping,
            is_material: deleteField(),
            material: deleteField(),
            nameLanguages: languageValue,
        }

        if (file) {
            let path = `/${props.id_restaurant}/food/${uuidv4()}/${file.name}`;

            await uploadFile(file, path)
                .on("state_changed",
                    (snapshot) => {
                        const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                        setPercent(percent);
                    },
                    (err) => console.log(err),
                    async () => {
                        try{
                            await deleteFile(props.foodItem.image)
                        }catch(err){
                            console.log(err);
                        }
                        
                            // download url
                        let fullPath = await getDownloadURL(ref(storage, path)).then((url: any) => {
                            return url;
                        });
                        data.image = convertImageUrl(fullPath);
                        await FirebaseFirestoreService.updateFoodItem(props.id_restaurant, props.foodItem.id, data);

                        clearState()
                        return
                    }
                );
        } else {
            await FirebaseFirestoreService.updateFoodItem(props.id_restaurant, props.foodItem.id, data);
            clearState()
        }
    }

    return (
        <Fragment>
            <Modal
                onClose={() => {
                    props.setOpenModal(false);
                }}
                closeOnDimmerClick={true}
                open={props.openModal}
                size="large"
                closeIcon={true}
            >
                <Modal.Header>{t("Food_Items.modal_item.update.header")}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Form.Group widths='equal'>
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.modal_item.update.name")}</label>
                                <input placeholder={t("Food_Items.modal_item.update.placeholder.name")} value={name} onChange={(e) => setName(e.target.value)} />
                            </Form.Field>
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.table.print_name")}</label>
                                <input placeholder={t("Food_Items.table.print_name")} value={printName} onChange={(e) => setPrintName(e.target.value)} />
                            </Form.Field>
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.modal_add.option")}</label>
                                <Select
                                    isSearchable={false}
                                    isMulti={true}
                                    options={listOptions}
                                    defaultValue={valueOptions}
                                    placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                                    onChange={handleChangeOptions}
                                />
                            </Form.Field>
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.modal_add.topping")}</label>
                                <Select
                                isSearchable={false}
                                isMulti={true}
                                options={listTopping}
                                defaultValue={valueTopping}
                                placeholder={t("Food_Items.modal_add.placeholder.add_option")}
                                onChange={handleChangeTopping}
                                />
                            </Form.Field>
                        </Form.Group>

                        <Form.Field>
                            <label>{t("Food_Items.modal_add.block_time")}</label>
                            <Checkbox
                                toggle
                                checked={isBlockTime}
                                onChange={(e, data) => setIsBlockTime(data.checked)}
                            />
                        </Form.Field>
                        
                        {isBlockTime &&
                            <Fragment>

                                <Form.Group widths="equal">
                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.time_net")}</label>
                                        <Input labelPosition="right" type="number" placeholder="First Block Time" onChange={(e) => {
                                            setFirstBlockTimeNet((Number(e.target.value)));
                                        }}>
                                            <Label basic>Time</Label>
                                            <input value={firstBlockTimeNet} />
                                        </Input>
                                    </Form.Field>

                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.price")}</label>
                                        <Input labelPosition="right" type="number" placeholder={t("Food_Items.modal_add.placeholder.price")}
                                            onChange={(e) => {
                                                setPrice(e.target.value);
                                                if (taxRate) {
                                                    setPriceTax(Math.round(Number(e.target.value) + (Number(e.target.value) * Number(taxRate) / 100)).toString());
                                                } else {
                                                    setPriceTax(e.target.value);
                                                }
                                            }}>
                                            <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                                            <input value={price} />
                                        </Input>
                                    </Form.Field>
                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.tax_rate")}</label>
                                        <Dropdown
                                            placeholder="Select Tax Rate"
                                            fluid
                                            selection
                                            defaultValue={taxSelection[0].value}
                                            options={taxSelection}
                                            onChange={taxOnchange}
                                        />
                                    </Form.Field>
                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.post_tax")}</label>
                                        <Input labelPosition="right" type="number"
                                            placeholder={t("Food_Items.modal_item.update.placeholder.price")} onChange={(e) => {
                                                setPriceTax(e.target.value);
                                                if (taxRate) {
                                                    setPrice(Math.round((Number(e.target.value) / 1.1)).toString());
                                                } else {
                                                    setPrice(e.target.value);
                                                }
                                            }}>
                                            <Label basic>đ</Label>
                                            <input value={priceTax} />
                                        </Input>
                                    </Form.Field>
                                </Form.Group>


                                <Form.Group widths="equal">

                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.time_ks")}</label>
                                        <Input labelPosition="right" type="number" placeholder="Second Block Time" onChange={(e) => {
                                            setSecondBlockTimeNet((Number(e.target.value)));
                                        }}>
                                            <Label basic>Time</Label>
                                            <input value={secondBlockTimeNet} />
                                        </Input>
                                    </Form.Field>

                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.price")}</label>
                                        <Input labelPosition="right" type="number" placeholder={t("Food_Items.modal_add.placeholder.price")}
                                            onChange={(e) => {
                                                setSecondPrice(e.target.value);
                                                if (taxRate) {
                                                    setSecondPriceTax(Math.round(Number(e.target.value) + (Number(e.target.value) * Number(taxRate) / 100)).toString());
                                                } else {
                                                    setSecondPriceTax(e.target.value);
                                                }
                                            }}>
                                            <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                                            <input value={secondPrice} />
                                        </Input>
                                    </Form.Field>
                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.tax_rate")}</label>
                                        <Dropdown
                                            placeholder="Select Tax Rate"
                                            fluid
                                            selection
                                            defaultValue={taxSelection[0].value}
                                            options={taxSelection}
                                            onChange={taxOnchange}
                                        />
                                    </Form.Field>
                                    <Form.Field error={isError}>
                                        <label>{t("Food_Items.modal_add.post_tax")}</label>
                                        <Input labelPosition="right" type="number"
                                            placeholder={t("Food_Items.modal_item.update.placeholder.price")} onChange={(e) => {
                                                setSecondPriceTax(e.target.value);
                                                if (taxRate) {
                                                    setSecondPrice(Math.round((Number(e.target.value) / 1.1)).toString());
                                                } else {
                                                    setSecondPrice(e.target.value);
                                                }
                                            }}>
                                            <Label basic>đ</Label>
                                            <input value={secondPriceTax} />
                                        </Input>
                                    </Form.Field>
                                </Form.Group>
                            </Fragment>
                        }

                        { isBlockTime === false &&
                            <Form.Group widths="equal">
                                <Form.Field error={isError}>
                                    <label>{t("Food_Items.modal_add.price")}</label>
                                    <Input labelPosition="right" type="number" placeholder={t("Food_Items.modal_add.placeholder.price")}
                                        onChange={(e) => {
                                            setPrice(e.target.value);
                                            if (taxRate) {
                                                setPriceTax(Math.round(Number(e.target.value) + (Number(e.target.value) * Number(taxRate) / 100)).toString());
                                            } else {
                                                setPriceTax(e.target.value);
                                            }
                                        }}>
                                        <Label basic>{t("Food_Items.modal_add.unit")}</Label>

                                        <input value={price} />
                                    </Input>
                                </Form.Field>
                                <Form.Field error={isError}>
                                    <label>{t("Food_Items.modal_add.tax_rate")}</label>
                                    <Dropdown
                                        placeholder="Select Tax Rate"
                                        fluid
                                        selection
                                        defaultValue={taxSelection[0].value}
                                        options={taxSelection}
                                        onChange={taxOnchange}
                                    />
                                </Form.Field>
                                <Form.Field error={isError}>
                                    <label>{t("Food_Items.modal_add.post_tax")}</label>
                                    <Input labelPosition="right" type="number"
                                        placeholder={t("Food_Items.modal_item.update.placeholder.price")} onChange={(e) => {
                                            setPriceTax(e.target.value);
                                            if (taxRate) {
                                                setPrice(Math.round((Number(e.target.value) / 1.1)).toString());
                                            } else {
                                                setPrice(e.target.value);
                                            }
                                        }}>
                                        <Label basic>đ</Label>
                                        <input value={priceTax} />
                                    </Input>
                                </Form.Field>
                            </Form.Group>
                        }

                        <Form.Group widths="equal">
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.modal_item.update.tag")}</label>
                                <input placeholder={t("Food_Items.modal_item.update.placeholder.tag")} value={tag} onChange={(e) => setTag(e.target.value)} />
                            </Form.Field>
                            <Form.Field></Form.Field>
                        </Form.Group>
                        <Form.Field>
                            <label>{t("Food_Items.modal_item.update.description")}</label>
                            <input placeholder={t("Food_Items.modal_item.update.placeholder.description")} maxLength={30} value={desc} onChange={(e) => setDesc(e.target.value)} />
                        </Form.Field>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                <label>{t("Food_Items.modal_item.update.category")}</label>
                                <Select
                                    isMulti={true}
                                    options={listCate}
                                    defaultValue={value}
                                    placeholder={t("Food_Items.modal_item.update.placeholder.category")}
                                    onChange={handleChange}
                                    styles={colourStyles}
                                />
                            </Form.Field>
                            <Form.Field error={isError}>
                                <label>{t("Food_Items.modal_item.update.category")}</label>
                                <input placeholder='Nhập tại đây' accept="image/*" type={"file"} onChange={handleChangeUpload} />

                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field>
                                {/* <label>Option</label>
                                <OptionFoodForm /> */}
                            </Form.Field>
                            <Form.Field>
                                {imagePath && <Image className="image-food-item" src={imagePath} />}
                                {file && <Fragment>
                                    <Image className="image-food-item" src={URL.createObjectURL(file)} />
                                    <Progress percent={percent} inverted progress />
                                </Fragment>}
                            </Form.Field>
                        </Form.Group>

                          {restaurant?.listFoodLanguages?.length > 0 && <Form.Field>
                            <Collapse>
                                <Collapse.Panel header="Thiết lập ngôn ngữ" key="1">
                                    <Row gutter={[15,15]}>
                                        {restaurant.listFoodLanguages.map((item: any, index: number)=>{
                                            return <Col lg={12} sm={24}>
                                                    <label>{item.value}</label>
                                                    <input placeholder='Tên' value={languageValue && languageValue[item.key] && languageValue[item.key].name} onChange={(e)=>{
                                                        if(!languageValue[item.key]){
                                                            languageValue[item.key] = {
                                                                name: '',
                                                                description: ''
                                                            }
                                                        }

                                                        if(!restaurant.listFoodLanguages[index]){
                                                            restaurant.listFoodLanguages[index] = {
                                                                name: '',
                                                                description: ''
                                                            }
                                                        }
                                                        restaurant.listFoodLanguages[index].name = e.target.value;
                                                        languageValue[item.key].name = e.target.value;
                                                        setLanguageValue({...languageValue})
                                                    }}/>
                                                    <textarea placeholder='Mô tả'rows={2} style={{marginTop: 10}} value={languageValue && languageValue[item.key] && languageValue[item.key].description} onChange={(e)=>{
                                                        const value = {...languageValue};
                                                        if(!value[item.key]){
                                                            value[item.key] = {
                                                                name: '',
                                                                description: ''
                                                            }
                                                        }
                                                        restaurant.listFoodLanguages[index].description = e.target.value;
                                                        value[item.key].description = e.target.value;
                                                        setLanguageValue(value)
                                                    }}/>
                                            </Col>
                                        })}
                                    </Row>
                                </Collapse.Panel>
                            </Collapse>
                        </Form.Field>}

                        <Form.Field>
                            <label>Liên kết kho hàng</label>
                            <Checkbox toggle checked={intergrateInventory} onChange={(e:any, {checked}:any)=> onIntergrateInventory(checked)} /><span></span>
                        </Form.Field>
                        {intergrateInventory && <LinkInventory isError={isError} resId={props.id_restaurant}
                                                               currentItem={props.foodItem}
                                                               handleFormChange={(type: string, data: any) => {
                                                                   setInventoryItem({
                                                                       type: type, data: data
                                                                   })
                                                               }
                                                               }/>}
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={t("Food_Items.modal_item.update.btn_confirm")}
                        labelPosition='right'
                        icon='save'
                        onClick={onSubmit}
                        primary
                    />
                </Modal.Actions>
            </Modal >
        </Fragment>
    )
}
export default ModalFoodItemUpdate;


const LinkInventory: FC<any> = (props) => {//currentItem
    const initType = props?.currentItem?.is_material ? "0" : "1"
    const initItem = (!props?.currentItem?.is_material && props?.currentItem?.material && props?.currentItem?.material[0]) ? {
        price: props?.currentItem?.material[0]?.price,
        min_quantity: props?.currentItem?.material[0]?.min_quantity,
        unit: props?.currentItem?.material[0]?.unit
    }: {
        price: '',
        min_quantity: '',
        unit: ''
    }

    const initInventory = (props?.currentItem?.is_material && props?.currentItem?.material) ? props?.currentItem?.material.map((item: any) => {
        return {
            label: item.name,
            name: item.name,
            quantity_required: item.quantity_required,
            unit: item.unit,
            value: item.id
        }
    }) : [];

    const [type, setType] = useState(initType);
    const [listInventoryItem, setListInventoryItem] = useState<any[]>([]);
    const [itemSelected, setItemSelected] = useState<any[]>(initInventory);
    const [itemInput, setItemInput] = useState<any>(initItem);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        setItemSelected(initInventory)
        setItemInput(initItem)
        if (type === "0") props.handleFormChange("material", initInventory);
        if (type === "1") props.handleFormChange("items", initItem);
    }, [type]);

    const fetchData = async () => {
        const data = await FirebaseFirestoreService.getIngredientInventoriesTree(props.resId);
        setListInventoryItem(data);
    }

    const handleSelectChange = async (newValue: any, data: any) => {
        setItemSelected(newValue);
        props.handleFormChange("material", newValue);
    }

    const handleChange = (e:any, { value }: any) => setType( value )

    const handleFormChange = (index:any, event:any) => {
        let data = [...itemSelected];
        data[index][event.target.name] = event.target.value;
        setItemSelected(data);
        props.handleFormChange("material", data);
    }

    const handleItemChange = (event: any) => {
        let newItem = {...itemInput}
        newItem[event.target.name] = event.target.value;
        setItemInput(newItem);
        props.handleFormChange("items", newItem);
    }

    return (
        <Fragment>
            <Form.Group inline>
                <Form.Radio
                    label='Nguyên liệu'
                    checked={type === '0'}
                    value='0'
                    onChange={handleChange}
                />
                <Form.Radio
                    label='Mặt hàng'
                    value='1'
                    checked={type === '1'}
                    onChange={handleChange}
                />
            </Form.Group>
            {type === '0' && <Fragment>
                <Select
                    className="tree-select"
                    isMulti={true}
                    options={listInventoryItem}
                    defaultValue={itemSelected}
                    placeholder='Chọn mặt hàng, nguyên liệu'
                    styles={colourStyles}
                    onChange={handleSelectChange}
                />
                <Table celled striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell scope="col">Tên nguyên liệu</Table.HeaderCell>
                            <Table.HeaderCell scope="col">Định lượng</Table.HeaderCell>
                            <Table.HeaderCell scope="col">Đơn vị</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {itemSelected?.length > 0 ? itemSelected.map((item, index) => {
                            return (
                                <Table.Row key={item.id}>
                                    <Table.Cell>{item.name}<input type={"hidden"} value={item.id} /></Table.Cell>
                                    <Table.Cell>
                                        <Form.Field error={props.isError}>
                                            <input type={"number"} name={'quantity_required'} value={item.quantity_required} onChange={event => handleFormChange(index, event)}/>
                                        </Form.Field>
                                    </Table.Cell>
                                    <Table.Cell>{item.unit}</Table.Cell>
                                </Table.Row>
                            )
                        }): <Table.Row><Table.Cell>N/A</Table.Cell></Table.Row>}
                    </Table.Body>
                </Table>
            </Fragment>}
            {type === '1' && <Fragment>
                <Form.Group widths='equal'>
                    <Form.Field error={props.isError}>
                        <label>Số lượng tối thiểu</label>
                        <input type={"number"} defaultValue={itemInput?.min_quantity} name={'min_quantity'} onChange={handleItemChange}/>
                    </Form.Field>
                    <Form.Field error={props.isError}>
                        <label>Đơn vị</label>
                        <select name={'unit'} defaultValue={itemInput?.unit} onChange={handleItemChange}>
                            <option value={''}></option>
                            {listUnit.map(item => (<option value={item.value}>{item.text}</option>))}
                        </select>
                    </Form.Field>
                    <Form.Field error={props.isError}>
                        <label>Đơn giá </label>
                        <input defaultValue={itemInput?.price} type={"number"} name={'price'} onChange={handleItemChange}/>
                    </Form.Field>
                </Form.Group>
            </Fragment>}
        </Fragment>
    )
}