import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface RestaurantState {
    id: string;
    name: string;
}

export const initialState: RestaurantState = {
    id: '',
    name: ''
};

// export const selectRestaurantState = (rootState: RootState) => rootState.restaurant;
// export const selectRestaurantId = (rootState: RootState) => rootState.restaurant.id;

const restaurantReducerSlice = createSlice({
    name: 'restaurantReducer',
    initialState: initialState,
    reducers: {
        setRestaurantId: (state, action: PayloadAction<any>) => {
            return {
                ...state,
                id: action.payload.id
            }
        }
    }
});

export const { setRestaurantId } = restaurantReducerSlice.actions;
export default restaurantReducerSlice.reducer;