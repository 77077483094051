import LoadableComponent from "../components/loading/LoadableComponet";

export const applicationRouters: any[] = [
    {
        path: "/", name: "Restaurant.name", title: "Restaurant.title", crumb: "BreadScrumb_Contact", icon: 'utensils', exact: true,
        isProtected: true, permission: 0,
        component: LoadableComponent(() => import('../components/views/restaurant/RestaurantAdmin'))
    },
    {
        path: "/home", name: "dashboard.dashboard", title: "dashboard.dashboard", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "home", exact: true,
        isProtected: true, permission: 1, isInvisible: true,
        component: LoadableComponent(() => import('../components/views/dashboard/Dashboard'))
    },
    {
        path: "/order", name: "Order.name", title: "Order.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "chart line", exact: true,
        isProtected: true, permission: 1, isInvisible: true,
        component: LoadableComponent(() => import('../components/views/order/Order'))
    },
    {
        path: "/shift", name: "Shift.name", title: "Shift.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "laptop", exact: true,
        isProtected: true, permission: 1, isInvisible: true,
        component: LoadableComponent(() => import('../components/views/shift/Shift'))
    },
    {
        path: "/transaction", name: "Transaction.name", title: "Transaction.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "food", exact: true,
        isProtected: true, permission: 1, isInvisible: true,
        component: LoadableComponent(() => import('../components/views/transaction/Transaction'))
    },
    {
        path: "/transaction-e-invoice", name: "Hóa đơn", title: "Transaction.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "credit card outline", exact: true,
        isProtected: true, permission: 1, isInvisible: true,
        component: LoadableComponent(() => import('../components/views/transaction-e-invoice/Transaction'))
    },
    {
        path: "/account", name: "Account.name", title: "Account.title", crumb: "BreadScrumb_Contact", icon: 'user', exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/account/ManageAccount'))
    },
    {
        path: "/table", name: "Table.name", title: "Table.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "table", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/table/TableView'))
    },
    {
        path: "/category", name: "Category.name", title: "Category.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "microsoft", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/category/Category'))
    },
    {
        path: "/food", name: "Food_Items.name", title: "Food_Items.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "coffee", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/food/FoodView'))
    },
    {
        path: "/options", name: "Food_Options.name", title: "Food_Options.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "options", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/options/Options'))
    },
    {
        path: "/topping", name: "Food_Topping.name", title: "Food_Topping.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "blackberry", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/topping/Topping'))
    },
    {
        path: "/inventories", name: "inventory.name", title: "inventory.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "warehouse", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/inventory/Inventory'))
    },
    {
        path: "/promotion", name: "Promotion.name", title: "Promotion.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "bullhorn", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/promotion/Promotion'))
    },
    {
        path: "/payment", name: "Payment.name", title: "Payment.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "payment", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/payment/Payment'))
    },
    {
        path: "/ads", name: "advertisement.advertisement", title: "advertisement.advertisement", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "setting", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/ads/Advertisement'))
    },
    {
        path: "/setting", name: "LanguageSetting.name", title: "LanguageSetting.name", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "language", exact: true,
        isProtected: true, permission: 0,
        component: LoadableComponent(() => import('../components/views/setting'))
    },
    {
        path: "/working-time/:id", name: "working_time.name", title: "working_time.title", crumb: "MenuHeaderPopup_Subscriptions_Headline", icon: "camera retro", exact: false, hidden: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/working-time/WorkingTime'))
    },
    {
        path: "/e-invoice", name: "e-invoice.name", title: "e-invoice.title", crumb: "e-invoice", icon: "edge", exact: true,
        isProtected: true, permission: 1,
        component: LoadableComponent(() => import('../components/views/e-invoice/EInvoice'))
    }
]