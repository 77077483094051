import React, {Fragment, useEffect, useState} from "react";
import "./translation/i18n";
import {useTranslation} from 'react-i18next';
import './sematic/dist/semantic.min.css';
import './App.css';
import Login from './components/views/login/Login';
import {Link, Route, Switch, useHistory, useParams} from "react-router-dom";
import PrivateRoute from './router/PrivateRoute';
import {applicationRouters} from './router/router.config';
import {Container, Icon, Image, Menu, Popup, Sidebar} from "semantic-ui-react";
import {useMediaQuery} from "react-responsive";
import NotFoundRouter from "./router/NotFoundRouter";
import FirebaseAuthService from "./services/FirebaseAuthService";
import {useSelector} from "react-redux";
import {selectRestaurantPermission, selectuser, selectUserIsLogin, setUserLogin, setUserLogout} from "./redux/userSlice";
import {useAppDispatch} from "./redux/store";
import FirebaseFirestoreService from "./services/FirebaseFirestoreService";
import bgImage from "./assets/bg-login.jpg";
import DropdownProfileLanguage from "./components/layouts/DropdownProfileLanguage";
import DropdownProfile from "./components/layouts/DropdownProfile";
import useVH from "react-viewport-height";
import FoodView from "./components/views/food/FoodView";

function App() {
  const { t } = useTranslation();

  // useVH();
  const getPermission = useSelector(selectRestaurantPermission);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [activeItem, setActiveItem] = useState(0);
  const isLoggedIn = useSelector(selectUserIsLogin);
  const user = useSelector(selectuser);
  const dispatchReduxToolkit = useAppDispatch();

  const [imagePath, setImagePath] = useState('');

  useEffect(() => {
    if (getPermission === 0) {
      setActiveItem(0);
    } else if (getPermission === 1) {
      setActiveItem(1);
    }
  }, [getPermission]);

  useEffect(() => {
    FirebaseAuthService.changeState(async (e: any) => {
      if (e) {

        const userDetail = await FirebaseFirestoreService.getUserDetail(e.uid);
        if (userDetail && userDetail.is_active) {
          if (userDetail.permission !== 0) {
            const imgProfile = await FirebaseFirestoreService.getRestaurantDetail(userDetail.id_restaurant);
            setImagePath(imgProfile.data()?.imageDefault)
            // dispatchReduxToolkit(setPhotoURL(imgProfile.data()?.imageDefault || ''));
          }
          dispatchReduxToolkit(setUserLogin(userDetail));

        } else {
          //TODO: account not mapping
        }

      } else {
        dispatchReduxToolkit(setUserLogout());
      }
    });
  }, [])

  const logout = async () => {
    await FirebaseAuthService.logoutUser();
    history.push("/login");
  };

  // event handlers
  const handlePusher = () => {
    if (visible) {
      setVisible(false);
    }
  };
  const handleToggle = () => setVisible(prev => !prev);
  const handleItemClick = (key: number) => () => {
    // setVisible(false);
    setActiveItem(key);
  };

  // styles
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 768px)"
  });
  const topMenuStyle = isDesktopOrLaptop ? { paddingLeft: "7.5em", fontSize: "30px" } : { paddingLeft: "0em", fontSize: "20px" };

  const FoodViewNew = () => {
    const param: any = useParams();
    return <FoodView id={param.id} />;
  };

  const containerStyle = isDesktopOrLaptop
    ? { marginTop: "7.1em", overflowY: "auto", padding: 10, overflowX: "hidden", height: "calc(100vh - 150px)" }
    : { marginTop: "7.1em", overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 150px)" };

  return (
    <Switch>

      <Route path="/login" exact component={Login} />
      <Route path="/login-iframe" exact component={Login} />

      <PrivateRoute
        exact={true}
        path={"/food-edit"}
        isSignIn={isLoggedIn}
        role={user.permission}
        isUseIframe={true}
        component={FoodView}
        permission={1}
      />

      <PrivateRoute
        exact={true}
        path={"/food-edit/:id"}
        isSignIn={isLoggedIn}
        role={user.permission}
        isUseIframe={true}
        component={FoodViewNew}
        permission={0}
      />

      {/* <Route path="/food-edit/:id" exact component={FoodViewNew}/>
            <Route path="/food-edit" exact component={FoodView}/> */}

      <Route path="/login" exact component={Login} />

      {applicationRouters.map((route: any, index: any) => {
        return (
          <Route key={index} path={route.path} exact={route.exact} render={(routeProps) => {
            const renderRoute = <PrivateRoute key={index} {...routeProps} exact={route.exact}
              isSignIn={isLoggedIn}
              role={user.permission}
              component={route.component} permission={route.permission} />

            document.title = t(`${route.title}`)

            return (
              <Fragment>
                <Sidebar.Pushable>
                <Sidebar
                    className={`side-bar-left ${collapse && 'collapse'}`}
                    as={Menu}
                    animation="overlay"
                    vertical
                    visible={visible}
                  >
                    {/* <Image src={bgImage} size='medium' /> */}
                    <div className="side-bar-left__nav">
                    <Menu.Item
                        onClick={()=>setVisible(!visible)}
                        icon={'sidebar'}
                        content={<span>PPOS</span>}
                        className="collapse-icon"
                      />
                      {applicationRouters.map((item, index) => {
                        if (item.permission === user.permission || user.permission === 0)
                          return (
                            <Popup
                              key={index}
                              content={t(`${item.name}`)}
                              position="left center"
                              on="hover"
                              trigger={
                                <Menu.Item
                                  active={activeItem === index}
                                  as={Link}
                                  key={index}
                                  onClick={handleItemClick(index)}
                                  link={true}
                                  icon={item.icon}
                                  content={t(`${item.name}`)}
                                  to={item.path}
                                />
                              }
                            />
                          )
                        else return (<Fragment></Fragment>)
                      })}
                    </div>
                  </Sidebar>

                  <Sidebar.Pusher
                    className={`${collapse && 'collapse'}`}
                    dimmed={visible}
                    onClick={handlePusher}
                    style={{ minHeight: "100vh" }}
                  >
                    <Menu inverted borderless fixed="top" className="header-menu">
                      <Menu.Item onClick={handleToggle}>
                        <Icon name="sidebar" />
                      </Menu.Item>
                      <Menu.Item
                        className={`header-menu-title ${visible && 'visible'}`}
                        style={topMenuStyle}
                        header
                        content={t(`${applicationRouters[activeItem].name}`)}
                      />
                      <Menu.Item className="menu-dropdown-language">
                        <DropdownProfileLanguage miniSize={true} />
                      </Menu.Item>
                      <Menu.Item className="dropdown-profile">
                        <DropdownProfile displayName={user.displayName} urlImage={imagePath} logout={logout} />
                      </Menu.Item>
                    </Menu>
                    <Container fluid style={containerStyle} className={`${visible && 'visible'}`}>
                      {renderRoute}
                      <div style={{ height: "120px" }}></div>
                    </Container>
                  </Sidebar.Pusher>
                </Sidebar.Pushable>
              </Fragment>
            )
          }}
          />)
      })}
      {/* <Redirect to={{ pathname: '/login' }} /> */}
      <Route component={NotFoundRouter} />
    </Switch>

  );
}

export default App;
