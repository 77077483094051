import { storage } from "../FireBaseConf";
import { deleteObject, ref, uploadBytesResumable } from "firebase/storage";

// const storageRef = ref(storage, 'some-child');


export const uploadFile = (file: any, path: string) => {

  const storageRef = ref(storage, path);

  const metadata = {
    cacheControl: 'max-age=36000000',
  };

  return uploadBytesResumable(storageRef, file, metadata);
}

const extractFileRef = (fileDownLoad: string) => {
  const decodeURL = decodeURIComponent(fileDownLoad);
  const startIndex = decodeURL.indexOf("/o/") + 3;
  const endIndex = decodeURL.indexOf("?");
  return decodeURL.substring(startIndex, endIndex);
}

export const deleteFile = async (fileDownLoad: string) => {
  const filePath = extractFileRef(fileDownLoad);
  const desertRef = ref(storage, filePath);
  return await deleteObject(desertRef);
}
