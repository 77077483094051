import { db } from "../FireBaseConf";

import {
    differenceInDays,
    eachDayOfInterval,
    endOfMonth,
    startOfDay,
    startOfMonth,
} from "date-fns";
import {
    addDoc,
    arrayUnion,
    collection,
    deleteDoc,
    doc,
    documentId,
    endAt,
    endBefore,
    getDoc,
    getDocs,
    limit,
    limitToLast,
    onSnapshot,
    orderBy,
    query,
    QueryConstraint,
    runTransaction,
    serverTimestamp,
    setDoc,
    startAfter,
    startAt,
    Timestamp,
    updateDoc,
    where,
} from "firebase/firestore";
import { toNumber } from "../helper/utils";
import Order from "../model/order";
import Restaurant from "../model/restaurant";
import Staff from "../model/staff";
import Table from "../model/table";
import User from "../model/user";
import FirebaseAuthService from "./FirebaseAuthService";

///////////////////Common/////////////////////

const getDocByRef = async (ref: any) => {
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document!");
        return null;
    }
};

///////////////////User/////////////////////

const getUserDetail = async (uid: string) => {
    const docRef = doc(db, "user", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document!");
        return null;
    }
};
const getUserByEmail = async (email: string) => {
    const q = query(collection(db, "user"), where("email", "==", email));
    const userDocs = await getDocs(q);
    return userDocs.size > 0 ? userDocs.docs.at(0) : null;
};

const createUser = async (uid: string, restaurant: any, email: string, displayName: string) => {
    return await setDoc(doc(db, "user", uid), {
        is_active: true,
        permission: 1,
        id_restaurant: restaurant.id,
        ref_restaurant: restaurant.ref,
        email: email,
        displayName: displayName,
        created: serverTimestamp(),
        restaurants: [restaurant.id],
        photoURL: 'https://react.semantic-ui.com/images/avatar/small/nan.jpg'
    });
};

const addUserAdmin = async (id_restaurant: string, email: string, displayName: string, isExist: boolean, photoURL?: string) => {
    if (isExist) {
        const currentUser = await getUserByEmail(email);
        if (!currentUser) return 1;
        const userRef = doc(db, "user", currentUser.id);
        if (currentUser.data()?.restaurants) {
            if (currentUser.data().restaurants.includes('id_restaurant')) {
                console.log("account already in restaurant");
                return 1;
            }
        } else {
            await updateDoc(userRef, {
                restaurants: []
            });
        }

        return await updateDoc(userRef, {
            restaurants: arrayUnion(id_restaurant)
        });

    } else {
        return FirebaseAuthService.registerUser(email)
            .then(async (docRef) => {
                await FirebaseAuthService.updateProfileUser(displayName, photoURL ? photoURL : null, docRef.user);
                const restaurant = await getRestaurantDetail(id_restaurant);
                if (restaurant) {
                    return await createUser(docRef.user.uid, restaurant, email, displayName);
                } else {
                    return 1;
                }
            })
            .catch((e) => {
                console.log(e);
                return 1;
            });
    }
};

const editUserAdmin = async (
    idUser: string,
    data: any
) => {
    const tableRef = doc(db, "user", idUser);
    return await updateDoc(tableRef, {
        ...data
    });
};

const getUserAdminByResId = (
    id_restaurant: string,
    snapshot: any,
    error: any
) => {
    const q = query(
        collection(db, "user"), where("restaurants", "array-contains-any", [id_restaurant])
    );
    return onSnapshot(q, snapshot, error);
};

const deleteUserAdmin = async () => {
    return await deleteDoc(doc(db, "restaurant", "068ewBEHUzvPww4xT0m2", "food", "qtRKPyn5I7FxHjmi3fkz"));
};

const createStaff = async (idRes: string, staff: Staff) => {
    return await setDoc(doc(db, "restaurant", idRes, "staff", staff.code), {
        is_active: true,
        is_login: false,
        displayName: staff.displayName,
        created: serverTimestamp(),
    });
};

const editStaff = async (
    idRes: string,
    idStaff: string,
    data: any
) => {
    const tableRef = doc(db, "restaurant", idRes, "staff", idStaff);
    return await updateDoc(tableRef, {
        ...data
    });
};

const deleteStaff = (idRes: string, id: string) => {
    const staffRef = doc(db, "restaurant", idRes, "staff", id);
    return deleteDoc(staffRef);
};

const changeStatusUserAdmin = (id: string, currentStatus: boolean) => {
    const userRef = doc(db, "user", id);
    return updateDoc(userRef, {
        is_active: !currentStatus,
    });
};

const streamStaffListItems = (
    idRes: string,
    snapshot: any,
    error: any
) => {
    const itemsColRef = collection(db, "restaurant", idRes, "staff");
    const itemsQuery = query(itemsColRef, orderBy("created", "desc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const changeStatusStaff = (
    idRes: string,
    code: string,
    currentStatus: boolean
) => {
    const staffRef = doc(db, "restaurant", idRes, "staff", code);
    return updateDoc(staffRef, {
        is_active: !currentStatus,
    });
};

const countUser = async () => {
    const querySnapshot = await (await getDocs(collection(db, "user"))).size;
    return querySnapshot;
};

///////////////////Restaurant/////////////////////

const getRestaurantDetail = async (id: string,) => {
    const docRef = doc(db, "restaurant", id);
    return getDoc(docRef);
};

const streamRestaurantDetail = async (idRes: string, snapshot: any, error: any) => {
    return onSnapshot(doc(db, "restaurant", idRes), snapshot, error);
};

const createRestaurant = ({
                              name,
                              address,
                              addressSub,
                              // tax_number,
                              phone,
                              passwordLv2,
                              imageDefault
                          }: Restaurant) => {
    const restaurantRef = collection(db, "restaurant");
    return addDoc(restaurantRef, {
        name: name,
        address: address,
        passwordLv2: passwordLv2,
        addressSub: addressSub || "",
        phone: phone,
        // tax_number: tax_number,
        created: serverTimestamp(),
        imageDefault: imageDefault
    });
};

const updateRestaurant = ({
                              id,
                              name,
                              address,
                              addressSub,
                              phone,
                              passwordLv2,
                              imageDefault
                          }: Restaurant) => {
    const restaurantRef = doc(db, "restaurant", id);
    return updateDoc(restaurantRef, {
        name: name,
        address: address,
        phone: phone,
        addressSub: addressSub || "",
        passwordLv2: passwordLv2,
        imageDefault: imageDefault
    });
};

const updateRestaurantCustomerIdApp = (id: string, devices: any[]) => {
    const restaurantRef = doc(db, "restaurant", id);
    console.log(devices);
  
    return updateDoc(restaurantRef, {
      devices,
      deviceNumber: devices.length,
    });
  };

const updateRestaurantBuffet = (
  idRes: string,
  data: { alertOntimeRemain?: number; messageOpenAlert?: string }
) => {
  const restaurantRef = doc(db, "restaurant", idRes);
  return updateDoc(restaurantRef, {
    buffet: data,
  });
};

const updateRestaurantScreen = (
  idRes: string,
  data: { textColor?: string; backgroundColor?: string }
) => {
  const restaurantRef = doc(db, "restaurant", idRes);
  return updateDoc(restaurantRef, {
    screen: data,
  });
};

const updateRestaurantQrText = (idRes: string, textContent: any) => {
  const restaurantRef = doc(db, "restaurant", idRes);
  return updateDoc(restaurantRef, {
    qrCodeText: textContent,
  });
};

const deleteRestaurant = (id: string) => {
    const restaurantRef = doc(db, "restaurant", id);
    return deleteDoc(restaurantRef);
};

const getRestaurantListItemsPeriodByNext = (
  data: any,
  snapshot: any,
  error: any
) => {
  if (!data) return;

  const itemsColRef = collection(db, "restaurant");
  const itemsQuery = query(
    itemsColRef,
    orderBy("created", "desc"),
    startAfter(data.created),
    limit(20)
  );
  return onSnapshot(itemsQuery, snapshot, error);
};

const getRestaurantListItemsPeriodByPrevious = (
  data: any,
  snapshot: any,
  error: any
) => {
  if (!data) return;

  const itemsColRef = collection(db, "restaurant");
  const itemsQuery = query(
    itemsColRef,
    orderBy("created", "desc"),
    endBefore(data.created),
    limit(20)
  );
  return onSnapshot(itemsQuery, snapshot, error);
};

const getRestaurantListItemsPeriod = (snapshot: any, error: any) => {
  const itemsColRef = collection(db, "restaurant");
  const itemsQuery = query(itemsColRef, orderBy("created", "desc"), limit(20));
  return onSnapshot(itemsQuery, snapshot, error);
};

const streamRestaurantListItems = (user: User, snapshot: any, error: any) => {

    const constraints: QueryConstraint[] = [];
    if (user.permission !== 0) {
        constraints.push(where(documentId(), "in", user.restaurants))
    }
    const itemsColRef = collection(db, "restaurant");
    const itemsQuery = query(itemsColRef, ...constraints);
    return onSnapshot(itemsQuery, snapshot, error);
};

const streamCountRestaurant = (snapshot: any, error: any) => {
  const itemsColRef = collection(db, "restaurant");
  const itemsQuery = query(itemsColRef, orderBy("created", "desc"));
  return onSnapshot(itemsQuery, snapshot, error);
};

const streamSettingListItems = (idRes: string, snapshot: any, error: any) => {
  return onSnapshot(
    doc(db, "restaurant", idRes, "setting", idRes),
    snapshot,
    error
  );
};

///////////////////Table/////////////////////

const addTable = async (idRes: string, table: Table) => {
    const restaurantRef = collection(db, "restaurant", idRes, "table");

    return await addDoc(restaurantRef, {
        ...table,
        created: serverTimestamp()
    });
};

const streamTableListItems = (
    idRes: string,
    snapshot: any,
    error: any
) => {
    const itemsColRef = collection(db, "restaurant", idRes, "table");
    const itemsQuery = query(
        itemsColRef,
        orderBy("is_active", "desc"),
        orderBy("is_used", "desc"),
        orderBy("created", "desc")
    );
    return onSnapshot(itemsQuery, snapshot, error);
};

const updateTable = (
    idRes: string,
    idTable: string,
    data: { name: string; is_active: boolean }
) => {
    const tableRef = doc(db, "restaurant", idRes, "table", idTable);
    return updateDoc(tableRef, {
        ...data,
    });
};

const deleteTableItem = (idRes: string, id: string) => {
    const foodRef = doc(db, "restaurant", idRes, "table", id);
    return deleteDoc(foodRef);
};

///////////////////Category/////////////////////

const addCategory = async (idRes: string, category: any) => {
    const restaurantRef = collection(db, "restaurant", idRes, "category");

    return await addDoc(restaurantRef, {
        ...category,
        created: serverTimestamp(),
        is_active: true
    });
};

const streamSettingLanguage = (callback: (value: any) => void, error: () => void) => {
    const itemsColRef = collection(db, "setting");
    const itemsQuery = query(
      itemsColRef
    );
    return onSnapshot(itemsQuery, callback, error);
  };

const streamCategoryListItems = (
    idRes: string,
    idParentCate: string,
    snapshot: any,
    error: any
) => {
    const itemsColRef = collection(db, "restaurant", idRes, "category");
    const itemsQuery = query(
        itemsColRef,
        where("parent_id", "==", idParentCate),
        orderBy("order", "asc")
    );
    return onSnapshot(itemsQuery, snapshot, error);
};

const streamCategoryBuffetItems = async (idRes: string, idCate: string) => {
  if (!idCate) return;

  const docRef = doc(db, "restaurant", idRes, "category", idCate);
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    return { useTime: docSnap.data().useTime };
  } else {
    return null;
  }
};

const streamCategoryFullListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "category");
    const itemsQuery = query(itemsColRef, orderBy("order", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const updateOrderCategoryListItems = async (
    idRes: string,
    idCate: string,
    data: { order: number }
) => {
    const cateRef = doc(db, "restaurant", idRes, "category", idCate);
    return await updateDoc(cateRef, {
        ...data,
    });
};

const changeStatusCategory = (idRes: string, idCate: string, currentStatus: boolean) => {
    const cateRef = doc(db, "restaurant", idRes, "category", idCate);
    return updateDoc(cateRef, {
        is_active: !currentStatus,
    });
};

const updateCategory = (idRes: string, idCate: string, data: any) => {
    const tableRef = doc(db, "restaurant", idRes, "category", idCate);
    return updateDoc(tableRef, {
        ...data,
    });
};

const getCateTree = async (resId: string) => {
    const q = query(collection(db, "restaurant", resId, "category"), orderBy("order"), where("parent_id", "==", ""));
    const cateParent = await getDocs(q);
    const parent = cateParent.docs.map((item: any) => {
        return {id: item.id, ...item.data()}
    })
    var dataReturn: any = [];
    for (var i = 0; i < parent.length; i++) {
        dataReturn.push({
            value: parent[i].id,
            label: parent[i].name,
            isChild: false
        })
        const q1 = query(collection(db, "restaurant", resId, "category"), orderBy("order"), where("parent_id", "==", parent[i].id));
        const cateChild = await getDocs(q1);
        cateChild.forEach((item: any) => {
            dataReturn.push({
                value: item.id,
                label: item.data().name,
                isChild: true
            })
            // return { id: item.id, ...item.data() }
        });
        // parent[i]['child'] = child;
    }

    return dataReturn;
};

const getCategoryList = async (resId: string) => {
    const orderRef = collection(db, "restaurant", resId, "category");
    const q = query(orderRef);
    const data = await getDocs(q);
    return data.docs.map((item: any) => {
        return {id: item.id, name: item.data().name}
    })
}

const getFoodByCategoryId: any = async (resId: string, categoryId: string) => {
    console.log(categoryId);
    
    const q = query(
      collection(db, "restaurant", resId, "food"),
      where("category_id", "array-contains", {
        value: categoryId
      })
    );
    const data = await getDocs(q);
    const arr = data.docs.map(item => {
      return item.data();
    })
    console.log(arr);
    
    return arr;
  };
  const getSubCategoryByCategoryId: any = async (resId: string, categoryId: string) => {
    console.log(categoryId);
    
    const q = query(
      collection(db, "restaurant", resId, "category"),
      where("parent_id", "==", categoryId)
    );
    const data = await getDocs(q);
    const arr = data.docs.map(item => {
      return item.data();
    })
    
    return arr;
  };
  const deleteCategory = async (idRes: string, id: string) => {
    const foodRef = doc(db, "restaurant", idRes, "category", id);
    return await deleteDoc(foodRef);
  };

const getOrderById = async (resId: string, code: string) => {
  const orderRef = collection(db, "restaurant", resId, "order");

  // Create a query to retrieve the order based on the code field
  const codeQuery = query(orderRef, where("order_id", "==", code));

  const querySnapshot = await getDocs(codeQuery);

  if (!querySnapshot.empty) {
    // Assuming that there's only one result, you can use .docs[0]
    const docSnapshot = querySnapshot.docs[0];
    const orderData = docSnapshot.data();
    return { id: docSnapshot.id, ...orderData };
  } else {
    // Handle case when the document doesn't exist
    return null;
  }
};

const categoryListForSelect = async (resId: string) => {
  const orderRef = collection(db, "restaurant", resId, "category");
  const q = query(orderRef);
  const data = await getDocs(q);
  return data.docs.map((item: any) => {
    return { value: item.id, label: item.data().name };
  });
};
  
///////////////////Food Item/////////////////////

const addFood = async (idRes: string, uid: string, food: any) => {
    const foodRef = collection(db, "restaurant", idRes, "food");

    return await addDoc(foodRef, {created: serverTimestamp(), ...food});
};

const streamFoodListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "food");
    const itemsQuery = query(itemsColRef, orderBy("order", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const getFoodListItemsAll = async (idRes: string) => {
    const itemsQuery = query(collection(db, "restaurant", idRes, "food"));
    const data = await getDocs(itemsQuery);

    return data.docs.map((item: any) => {
        return {id: item.id, ...item.data()}
    })
};

const getFoodListItemsByCategory = async (idRes: string, arrCateId: { value: string }[]) => {
    const itemsColRef = collection(db, "restaurant", idRes, "food");
    const itemsQuery = query(itemsColRef, where("category_id", "array-contains-any", arrCateId), limit(20));
    const data = await getDocs(itemsQuery);

    return data.docs.map((item: any) => {
        return {id: item.id, ...item.data()}
    })
};

const changeStatusFood = (idRes: string, idFood: string, currentStatus: boolean) => {
    const foodRef = doc(db, "restaurant", idRes, "food", idFood);
    return updateDoc(foodRef, {
        is_active: !currentStatus,
    });
};

const updateFoodItem = (idRes: string, idFood: string, data: any) => {
    const foodRef = doc(db, "restaurant", idRes, "food", idFood);
    return updateDoc(foodRef, {
        ...data,
    });
};

const updateFoodItemOption = (idRes: string, idFood: string, data: any) => {
    const foodRef = doc(db, "restaurant", idRes, "food", idFood);
    return updateDoc(foodRef, {
        options: data
    });
};

const countFood = async (idRes: string) => {
    const querySnapshot = await (await getDocs(collection(db, "restaurant", idRes, "food"))).size;
    return querySnapshot;
};

const getFoodItemsPeriod = (resId: string, arrCateId: { value: string }[], snapshot: any, error: any) => {
    const orderRef = collection(db, "restaurant", resId, "food");

    if (arrCateId.length === 0) {
        const q = query(orderRef, orderBy("created", "desc"), limit(20));
        return onSnapshot(q, snapshot, error);
    } else {
        const q = query(orderRef, where("category_id", "array-contains-any", arrCateId), orderBy("created"), limit(20));
        return onSnapshot(q, snapshot, error);
    }
}

const getFoodItemsPeriodByNext = (resId: string, arrCateId: { value: string }[], data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "food");
    if (arrCateId.length === 0) {
        const q = query(orderRef, orderBy("created", "desc"), startAfter(data.created), limit(20));
        return onSnapshot(q, snapshot, error);
    } else {
        const q = query(orderRef, where("category_id", "array-contains-any", arrCateId), orderBy("created"), startAfter(data.created), limit(20));
        return onSnapshot(q, snapshot, error);
    }
}

const getFoodItemsPeriodByPrevious = (resId: string, arrCateId: { value: string }[], data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "food");
    if (arrCateId.length === 0) {
        const q = query(orderRef, orderBy("created", "desc"), endBefore(data.created), limitToLast(20));
        return onSnapshot(q, snapshot, error);
    } else {
        const q = query(orderRef, where("category_id", "array-contains-any", arrCateId), orderBy("created"), endBefore(data.created), limitToLast(20));
        return onSnapshot(q, snapshot, error);
    }
}

const deleteFoodItem = (idRes: string, id: string) => {
    const foodRef = doc(db, "restaurant", idRes, "food", id);
    return deleteDoc(foodRef);
};

///////////////////Promotion/////////////////////

const addPromotion = async (idRes: string, voucher: any) => {
    const promotionRef = collection(db, "restaurant", idRes, "voucher");

    return await addDoc(promotionRef, {
        ...voucher,
        created: serverTimestamp(),
        is_active: true
    });
};

const streamPromotionListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "voucher");
    const itemsQuery = query(itemsColRef, orderBy("created", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const changeStatusPromotion = (idRes: string, idPromotion: string, currentStatus: boolean) => {
    const promotionRef = doc(db, "restaurant", idRes, "voucher", idPromotion);
    return updateDoc(promotionRef, {
        is_active: !currentStatus,
    });
};

const updatePromotionItem = (idRes: string, idPromotion: string, data: any) => {
    const promotionRef = doc(db, "restaurant", idRes, "voucher", idPromotion);
    return updateDoc(promotionRef, {
        ...data,
    });
};

const deletePromotionItem = (idRes: string, id: string) => {
    const voucherRef = doc(db, "restaurant", idRes, "voucher", id);
    return deleteDoc(voucherRef);
};

///////////////////Payment/////////////////////

const addPayment = async (idRes: string, payment: any) => {
    const promotionRef = collection(db, "restaurant", idRes, "payment_method");

    return await addDoc(promotionRef, {
        ...payment,
        created: serverTimestamp(),
        is_active: true
    });
};

const streamPaymentListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "payment_method");
    const itemsQuery = query(itemsColRef);
    return onSnapshot(itemsQuery, snapshot, error);
};

const changeStatusPayment = (idRes: string, idPayment: string, currentStatus: boolean) => {
    const paymentRef = doc(db, "restaurant", idRes, "payment_method", idPayment);
    return updateDoc(paymentRef, {
        is_active: !currentStatus,
    });
};

const updatePaymentItem = (idRes: string, idPayment: string, data: any) => {
    const paymentRef = doc(db, "restaurant", idRes, "payment_method", idPayment);
    return updateDoc(paymentRef, {
        ...data,
    });
};

const deletePaymentItem = (idRes: string, id: string) => {
    const paymentRef = doc(db, "restaurant", idRes, "payment_method", id);
    return deleteDoc(paymentRef);
};

///////////////////Order/////////////////////

const getTotalOrder = async (resId: string) => {
  const result = (await getDocs(collection(db, "restaurant", resId, "order")))
    .size;
  return result;
};
  
const getOrderDetail = async (resId: string, orderId: string) => {
    const q = query(collection(db, "restaurant", resId, "order"), where("order_id", "==", orderId));
    return await getDocs(q);
};

const getOrderPeriod = (resId: string, startDate: Date, endDate: Date, snapshot: any, error: any) => {
    const orderRef = collection(db, "restaurant", resId, "order");
    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);

    const constraints: QueryConstraint[] = [];

    constraints.push(orderBy("createdAt", "desc"), startAt(date2), endAt(date1), limit(10))

    const q = query(orderRef, ...constraints);

    return onSnapshot(q, snapshot, error);
}

const getDataForDashboardFood = async (
  resId: string,
  startDate: Date,
  endDate: Date
) => {
  const orderRef = collection(db, "restaurant", resId, "shift");
  const q = query(
    orderRef,
    orderBy("open_time", "desc"),
    startAt(endDate),
    endAt(startDate)
  );
  const data = await getDocs(q);

  const eachDay = eachDayOfInterval({
    start: startOfMonth(startDate),
    end: endOfMonth(endDate),
  });

  const returnData = data.docs.reduce(
    (returnData: any, itemData: any) => {
      const item = itemData.data();
      if (item.close_time != "") {
        returnData.total_guests += toNumber(item.total_guests);
        returnData.total_bill += toNumber(item.total_bill);
        returnData.total_product += toNumber(item.total_product);
        returnData.amount_sale += toNumber(item.amount_sale);
        var currentDayRevenue = returnData.amount_per_day;

        eachDay.map((dateInWeek: Date) => {
          if (!currentDayRevenue[dateInWeek.toLocaleDateString()]) {
            currentDayRevenue[dateInWeek.toLocaleDateString()] = {
              revenue: 0,
            };
          }

          if (
            differenceInDays(
              dateInWeek,
              startOfDay(new Date(item.open_time.seconds * 1000))
            ) === 0
          ) {
            currentDayRevenue[dateInWeek.toLocaleDateString()]["revenue"] +=
              item.amount_sale || !isNaN(item.amount_sale)
                ? item.amount_sale
                : 0;
          }
        });
        returnData.amount_per_day = currentDayRevenue;
      }

      return returnData;
    },
    {
      total_guests: 0,
      total_bill: 0,
      total_product: 0,
      amount_sale: 0,
      amount_per_day: {},
    }
  );

  return returnData;
};

const deleteTransactionPhysical = (resId: string, orderId: string) => {
  const orderRef = doc(db, "restaurant", resId, "order", orderId);
  return deleteDoc(orderRef);
};

const addOrderBackup = async (idRes: string, order: Order) => {
  const orderBkRef = collection(db, "restaurant", idRes, "orderBk");

  return await addDoc(orderBkRef, {
    ...order,
    deletedAt: serverTimestamp(),
  });
};

const getSoldProduct = async (
  resId: string,
  startDate: Date,
  endDate: Date
) => {
  const orderRef = collection(db, "restaurant", resId, "order");
  const date1 = Timestamp.fromDate(startDate);
  const date2 = Timestamp.fromDate(endDate);

  const q = query(
    orderRef,
    where("status", "==", "complete"),
    orderBy("createdAt", "desc"),
    startAt(date2),
    endAt(date1)
  );
  const data = await getDocs(q);

  const returnData = data.docs.reduce(
    (returnData: any, item: any) => {
      var currentProductsPaid = returnData.productsPaid;

      item?.data()?.productsPaid?.map((ele: any) => {
        let priceProduct = ele.pricePostTax;
        // TODO: condition for tax
        let pricePreTax = ele.price;

        ele.options.forEach((option: any) => {
          const priceOption = option.type.find(
            (obj: any) => obj.isSelect === true
          )?.price;
          priceProduct += Number(priceOption);
          pricePreTax += Math.round(Number(priceOption) / 1.1);
        });

        if (currentProductsPaid[ele.id]) {
          currentProductsPaid[ele.id]["quantity"] +=
            ele.quantity || !isNaN(ele.quantity) ? ele.quantity : 0;
          currentProductsPaid[ele.id]["total_revenue"] +=
            ele.quantity * priceProduct || !isNaN(ele.quantity)
              ? ele.quantity * priceProduct
              : 0;
          currentProductsPaid[ele.id]["cate_list"] = ele.category_id
            ? ele.category_id.flatMap((elem: any) => elem.value)
            : [];
        } else {
          currentProductsPaid[ele.id] = {
            quantity: ele.quantity || !isNaN(ele.quantity) ? ele.quantity : 0,
            name: ele.name,
            category_id: ele.category_id,
            cate_list: ele.category_id
              ? ele.category_id.flatMap((elem: any) => elem.value)
              : [],
            total_revenue:
              ele.quantity * priceProduct || !isNaN(ele.quantity)
                ? ele.quantity * priceProduct
                : 0,
          };
        }
      });

      return returnData;
    },
    {
      productsPaid: [],
    }
  );

  return returnData;
};

const getOrderPeriodEInvoice = async (resId: string, startDate: Date, endDate: Date) => {
    const orderRef = collection(db, "restaurant", resId, "order");
    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);

    const constraints: QueryConstraint[] = [];

    constraints.push(orderBy("createdAt", "desc"), orderBy("dataInvoice", "desc"), where("createdAt", ">", date1), where("createdAt", "<", date2))

    const q = query(orderRef, ...constraints);

    const documentSnapshots = await getDocs(q);

    return documentSnapshots.docs.map((doc: any) => (
        {
            id: doc.id,
            // ...doc.data(),
            tax_amount: doc.data()?.tax_amount,
            final_cost: doc.data()?.final_cost,
            total_amount: doc.data()?.total_amount,
            discount_amount: doc.data()?.discount_amount,
            status: doc.data()?.status,
            payment_method: doc.data()?.payment_method,
            amount_refund: doc.data()?.amount_refund,
            order_id: doc.data()?.order_id,
            createdAt: doc.data()?.createdAt,
            table_name: doc.data()?.table_name,
            invoice_no: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].No : null,
            Buyer: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0]?.Buyer : null,
            CustomerAddress: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].CustomerAddress : null,
            CustomerName: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].CustomerName : null,
            LinkView: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].LinkView : null,
            TaxAuthorityCode: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].TaxAuthorityCode : null,
            ErrorCode: doc.data()?.dataInvoice?.ErrorCode,
            Message: doc.data()?.dataInvoice?.Message,
            Status: doc.data()?.dataInvoice?.Status,
            IssueDate: doc.data()?.dataInvoice?.Data?.Invoices ? doc.data()?.dataInvoice?.Data?.Invoices[0].IssueDate : null,
        }
    ));
}

const getOrderPeriodByNext = (resId: string, startDate: Date, endDate: Date, data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "order");

    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(orderRef, orderBy("createdAt", "desc"), startAt(date2), endAt(date1), startAfter(data.createdAt), limit(10));
    return onSnapshot(q, snapshot, error);
}

const getOrderPeriodByPrevious = (resId: string, startDate: Date, endDate: Date, data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "order");

    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(orderRef, orderBy("createdAt", "desc"), startAt(date2), endAt(date1), endBefore(data.createdAt), limitToLast(10));
    return onSnapshot(q, snapshot, error);
}

///////////////////addoptions/////////////////////

const addOptions = async (idRes: string, options: any) => {
    const optionsRef = collection(db, "restaurant", idRes, "options");

    return await addDoc(optionsRef, {
        ...options,
        created: serverTimestamp(),
        is_active: true
    });
};

const addTopping = async (idRes: string, toppings: any) => {
    const optionsRef = collection(db, "restaurant", idRes, "toppings");
  
    return await addDoc(optionsRef, {
      ...toppings,
      created: serverTimestamp(),
      is_active: true,
    });
  };

const streamOptionsListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "options");
    const itemsQuery = query(itemsColRef, orderBy("created", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const streamToppingListItems = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "toppings");
    const itemsQuery = query(itemsColRef, orderBy("created", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
  };

const streamOptionsListItemsOnActive = (idRes: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, "restaurant", idRes, "options");
    const itemsQuery = query(itemsColRef, where("is_active", "==", true), orderBy("created", "asc"));
    return onSnapshot(itemsQuery, snapshot, error);
};

const streamToppingListItemsOnActive = (
    idRes: string,
    snapshot: any,
    error: any
  ) => {
    const itemsColRef = collection(db, "restaurant", idRes, "toppings");
    const itemsQuery = query(
      itemsColRef,
      where("is_active", "==", true),
      // orderBy("created", "asc")
    );
    return onSnapshot(itemsQuery, snapshot, error);
  };

const changeStatusOptions = (idRes: string, idOptions: string, currentStatus: boolean) => {
    const optionsRef = doc(db, "restaurant", idRes, "options", idOptions);
    return updateDoc(optionsRef, {
        is_active: !currentStatus,
    });
};

const changeStatusTopping = (
    idRes: string,
    idTopping: string,
    currentStatus: boolean
  ) => {
    const optionsRef = doc(db, "restaurant", idRes, "toppings", idTopping);
    return updateDoc(optionsRef, {
      is_active: !currentStatus,
    });
  };

const updateOptions = (idRes: string, idOptions: string, data: any) => {
    const optionsRef = doc(db, "restaurant", idRes, "options", idOptions);
    return updateDoc(optionsRef, {
        ...data,
    });
};

const updateTopping = (idRes: string, idTopping: string, data: any) => {
    const optionsRef = doc(db, "restaurant", idRes, "toppings", idTopping);
    return updateDoc(optionsRef, {
      ...data,
    });
  };

const getOption = async (idRes: string, optionId: string) => {
    const docRef = doc(db, "restaurant", idRes, "options", optionId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return {name: docSnap.data().name, type: docSnap.data().type};
    } else {
        return null;
    }
};

///////////////////Tax/////////////////////
const getTaxDetailList = async (resId: string) => {
    const q = query(collection(db, "restaurant", resId, "tax"));
    return await getDocs(q);
};

////////////Management Account Staff//////////
const getTimeKeeping = (resId: string, staffId: string, startDate: Date, endDate: Date, snapshot: any, error: any) => {
    const timeKeepingRef = collection(db, "restaurant", resId, "timekeeping");
    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(timeKeepingRef, where("staffId", "==", staffId), orderBy("checkIn", "desc"), startAt(date2), endAt(date1), limit(10));

    return onSnapshot(q, snapshot, error);
}

///////////////////Shift/////////////////////
const getShiftPeriod = (resId: string, startDate: Date, endDate: Date, snapshot: any, error: any) => {
    const orderRef = collection(db, "restaurant", resId, "shift");
    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(
        orderRef,
        orderBy("open_time", "desc"),
        // startAt(date2),
        // endAt(date1),
        limit(10)
      );
    return onSnapshot(q, snapshot, error);
}

const getShiftPeriodByNext = (resId: string, startDate: Date, endDate: Date, data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "shift");

    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(orderRef, orderBy("open_time", "desc"), startAt(date2), endAt(date1), startAfter(data.open_time), limit(10));
    return onSnapshot(q, snapshot, error);
}

const getShiftPeriodByPrevious = (resId: string, startDate: Date, endDate: Date, data: any, snapshot: any, error: any) => {
    if (!data) return;
    const orderRef = collection(db, "restaurant", resId, "shift");

    const date1 = Timestamp.fromDate(startDate);
    const date2 = Timestamp.fromDate(endDate);
    const q = query(orderRef, orderBy("open_time", "desc"), startAt(date2), endAt(date1), endBefore(data.open_time), limitToLast(10));
    return onSnapshot(q, snapshot, error);
}

// const getCurrentShift = async (resId: string) => {
//   const orderRef = collection(db, "restaurant", resId, "shift");
//   const q = query(orderRef, where("close_time", "==", ""), orderBy("open_time", "desc"));
//   return await getDocs(q);
// }

// const getOrderCurrentShift = async (resId: string, updatedAt: any) => {
//   const orderRef = collection(db, "restaurant", resId, "order");
//   const q = query(orderRef, where("updatedAt", ">=", updatedAt));
//   return await getDocs(q);
// }

// const getOrderStillOnCurrentShift = async (resId: string, updatedAt: any) => {
//   const orderRef = collection(db, "restaurant", resId, "order");
//   const q = query(orderRef, where('status', '==', 'inprocess'), where("updatedAt", "<", updatedAt));
//   return await getDocs(q);
// }

///////////////////Ads/////////////////////

const getAdsListItems = async (idRes: string) => {
    const docRef = doc(db, "restaurant", idRes);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data().adsPath || [];
    } else {
        return null;
    }
};

const uploadAds = (idRes: string, data: any) => {
    const optionsRef = doc(db, "restaurant", idRes);
    return updateDoc(optionsRef, {
        adsPath: data
    });
};

const changeStatusUsePretax = (idRes: string, currentStatus: boolean) => {
    const docRef = doc(db, "restaurant", idRes);
    return updateDoc(docRef, {
        usePreTax: !currentStatus,
    });
};

const addInventory = async (idRes: string, inventoryItem: any) => {
    const inventoryRef = collection(db, "restaurant", idRes, "inventory_item");

    return await addDoc(inventoryRef, {
        ...inventoryItem,
        min_quantity: parseInt(inventoryItem.min_quantity),
        price: parseFloat(inventoryItem.price),
        created: serverTimestamp(),
        isMaterial: true
    });
};

const addItemInventory = async (idRes: string, inventoryItem: any) => {
    const inventoryRef = collection(db, "restaurant", idRes, "inventory_item");

    const data = await addDoc(inventoryRef, {
        ...inventoryItem,
        min_quantity: parseInt(inventoryItem.min_quantity),
        price: parseFloat(inventoryItem.price),
        created: serverTimestamp(),
        isMaterial: false
    });
    return data.id;
};

const updateInventory = async (idRes: string, idInventory: string, inventoryItem: any) => {
    const inventoryRef = doc(db, "restaurant", idRes, "inventory_item", idInventory);
    return updateDoc(inventoryRef, {
        ...inventoryItem,
        min_quantity: parseInt(inventoryItem.min_quantity),
        updatedAt: serverTimestamp()
    });
};

// const getInventories: any = async (resId: string) => {
//     const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
//     const q = query(inventoryRef, where("isMaterial", "==", true));
//     const data = await getDocs(q);
//     return data.docs.map(item => {
//         return {...item.data(), id: item.id}
//     })
// }

const getInventories: any = (resId: string, snapshot: any, error: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", true), orderBy("created", "desc"), limit(20));
    return onSnapshot(q, snapshot, error);
}

const getInventoriesByNext: any = async (resId: string, lastVisible: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", true), orderBy("created", "desc"), startAfter(lastVisible) , limit(20));
    return await getDocs(q);
}

const getInventoriesByPrevious: any = async (resId: string, firstVisible: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", true), orderBy("created", "desc"), endAt(firstVisible) , limitToLast(20));
    return await getDocs(q);
}

const getItems: any = (resId: string, snapshot: any, error: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", false), orderBy("created", "desc"), limit(20));
    return onSnapshot(q, snapshot, error);
}

const getItemsByNext: any = async (resId: string, lastVisible: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", false), orderBy("created", "desc"), startAfter(lastVisible) , limit(20));
    return await getDocs(q);
}

const getItemsByPrevious: any = async (resId: string, firstVisible: any) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const q = query(inventoryRef, where("isMaterial", "==", false), orderBy("created", "desc"), endAt(firstVisible) , limitToLast(20));
    return await getDocs(q);
}

// const getItems: any = async (resId: string) => {
//     const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
//     const q = query(inventoryRef, where("isMaterial", "==", false));
//     const data = await getDocs(q);
//     return data.docs.map(item => {
//         return {...item.data(), id: item.id}
//     })
// }

const getAllInventoriesTree: any = async (resId: string) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const data = await getDocs(query(inventoryRef));
    return data.docs.map(item => {
        return { name: item.data().name, label: item.data().name, value: item.id , price: '', inventory_quantity: ''}
    })
}

const getIngredientInventoriesTree: any = async (resId: string) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_item");
    const data = await getDocs(query(inventoryRef, where("isMaterial", "==", true)));
    return data.docs.map(item => {
        return { name: item.data().name, label: item.data().name, value: item.id , unit: item.data()?.unit, quantity_required: ''}
    })
}

const inOutInventory = async (type: 'import' | 'export', resId: string, listItem: any[], inventory_order: any, totalPrice:number) => {
    try {
        await runTransaction(db, async (transaction) => {
            await Promise.all(listItem.map(async (item, index) => {
                const inventoryItemRef = doc(db, "restaurant", resId, "inventory_item", item.id);
                const sfDoc = await transaction.get(inventoryItemRef);
                if (!sfDoc.exists()) {
                    throw "Document does not exist!";
                }
                let newPrice = 0;
                let newQuantity = 0;
                const currentQuantity = sfDoc.data()?.inventory_quantity ? sfDoc.data().inventory_quantity : 0;
                const currentPrice = sfDoc.data()?.price ? sfDoc.data().price : 0;

                if (type === 'import') {
                    newQuantity = currentQuantity + item.inventory_quantity;
                    newPrice = (currentPrice + item.price) / 2;
                }
                else if (type === 'export') {
                    newQuantity = currentQuantity - item.inventory_quantity;
                    const currentTotalPrice = (currentQuantity * currentPrice) - (item.inventory_quantity * item.price);
                    newPrice = currentTotalPrice / newQuantity;
                }
                await transaction.update(inventoryItemRef, {inventory_quantity: newQuantity, price: newPrice});
            }))
        });
        console.log("Transaction successfully committed!");
        //add inventory tracking
        const inventoryRef = collection(db, "restaurant", resId, "inventory_order");

        return await addDoc(inventoryRef, {
            description: inventory_order.description,
            status: inventory_order.status,
            detail: listItem,
            totalPrice: totalPrice,
            createdAt: serverTimestamp()
        });

    } catch (e) {
        console.log("Transaction failed: ", e);
        throw new Error("fail");
    }
}

const getInventoryOrderPeriod = async (resId: string, status: string) => {
    const inventoryRef = collection(db, "restaurant", resId, "inventory_order");
    const q = query(inventoryRef, where("status", "==", status), orderBy("createdAt"), limit(20));
    const data = await getDocs(q);
    return data.docs.map(item => {
        return {...item.data(), id: item.id}
    })
}

const getInventoryOrderPeriodByNext = async (resId: string, status: string, currentData: any) => {
    if (!currentData) return;
    const inventoryRef = collection(db, "restaurant", resId, "inventory_order");
    const q = query(inventoryRef, where("status", "==", status), orderBy("createdAt"), startAfter(currentData.createdAt), limit(20));
    const data = await getDocs(q);
    return data.docs.map(item => {
        return {...item.data(), id: item.id}
    })
}

const getInventoryOrderPeriodByPrevious = async (resId: string, status: string, currentData: any) => {
    if (!currentData) return;
    const inventoryRef = collection(db, "restaurant", resId, "inventory_order");
    const q = query(inventoryRef, where("status", "==", status), orderBy("createdAt"), endBefore(currentData.createdAt), limitToLast(20));
    const data = await getDocs(q);
    return data.docs.map(item => {
        return {...item.data(), id: item.id}
    })
}

const deleteItemInventory = (idRes: string, id: string) => {
    const itemRef = doc(db, "restaurant", idRes, "inventory_item", id);
    return deleteDoc(itemRef);
};

const updateItemInventory = async (idRes: string, idInventory: string, inventoryItem: any) => {
    const inventoryRef = doc(db, "restaurant", idRes, "inventory_item", idInventory);
    return updateDoc(inventoryRef, {
        ...inventoryItem,
        updatedAt: serverTimestamp()
    });
};

const updateIsUseEInvoice = async (idRes: string, isUseEInvoiceCurrent: boolean) => {
    const restRef = doc(db, "restaurant", idRes);
    return await updateDoc(restRef, {
        isUseEInvoice: !isUseEInvoiceCurrent
    });
};

const updateLanguage = (
    data: any
  ) => {
    const tableRef = doc(db, "setting", '1');
    return updateDoc(tableRef, {
      ...data,
    });
  };

const updateRestaurantLanguage = ({
languages,
id
}: any) => {
const restaurantRef = doc(db, "restaurant", id);
return updateDoc(restaurantRef, {
    listFoodLanguages: languages
});
};

const FirebaseFirestoreService = {
    updateRestaurantLanguage,
    updateLanguage,
    getUserDetail,
    getRestaurantDetail,
    createRestaurant,
    updateRestaurant,
    deleteRestaurant,
    streamRestaurantListItems,
    getShiftPeriod,
    getDocByRef,
    addUserAdmin,
    editUserAdmin,
    getUserAdminByResId,
    deleteUserAdmin,
    createStaff,
    streamStaffListItems,
    changeStatusUserAdmin,
    changeStatusStaff,
    editStaff,
    deleteStaff,
    addTable,
    streamTableListItems,
    updateTable,
    addCategory,
    streamCategoryListItems,
    updateOrderCategoryListItems,
    changeStatusCategory,
    updateCategory,
    changeStatusTopping,
    streamCategoryFullListItems,
    addFood,
    streamFoodListItems,
    changeStatusFood,
    updateFoodItem,
    updateFoodItemOption,
    countUser,
    addPromotion,
    streamPromotionListItems,
    changeStatusPromotion,
    updatePromotionItem,
    deletePromotionItem,
    addPayment,
    streamPaymentListItems,
    changeStatusPayment,
    getFoodByCategoryId,
    getSubCategoryByCategoryId,
    deleteCategory,
    updatePaymentItem,
    deletePaymentItem,
    getOrderDetail,
    getOrderPeriod,
    streamCountRestaurant,
    getRestaurantListItemsPeriodByNext,
    getRestaurantListItemsPeriodByPrevious,
    getRestaurantListItemsPeriod,
    streamSettingListItems,
    getOrderPeriodByNext,
    getOrderPeriodByPrevious,
    addOptions,
    addTopping,
    streamOptionsListItems,
    streamToppingListItems,
    changeStatusOptions,
    updateOptions,
    updateTopping,
    streamOptionsListItemsOnActive,
    streamToppingListItemsOnActive,
    getTaxDetailList,
    getOption,
    getCateTree,
    getShiftPeriodByNext,
    getShiftPeriodByPrevious,
    getCategoryList,
    getFoodListItemsByCategory,
    getFoodListItemsAll,
    countFood,
    uploadAds,
    getAdsListItems,
    getFoodItemsPeriod,
    getFoodItemsPeriodByNext,
    getFoodItemsPeriodByPrevious,
    deleteFoodItem,
    deleteTableItem,
    getTotalOrder,
    getSoldProduct,
    streamCategoryBuffetItems,
    updateRestaurantBuffet,
    updateRestaurantScreen,
    updateRestaurantQrText,
    getDataForDashboardFood,
    deleteTransactionPhysical,
    addOrderBackup,
    categoryListForSelect,
    getOrderById,
    changeStatusUsePretax,
    streamRestaurantDetail,
    getTimeKeeping,
    getUserByEmail,
    addInventory,
    getInventories,
    getInventoriesByNext,
    getInventoriesByPrevious,
    updateInventory,
    getItems,
    getItemsByNext,
    getItemsByPrevious,
    getAllInventoriesTree,
    updateRestaurantCustomerIdApp,
    inOutInventory,
    getInventoryOrderPeriod,
    getInventoryOrderPeriodByNext,
    getInventoryOrderPeriodByPrevious,
    getIngredientInventoriesTree,
    addItemInventory,
    deleteItemInventory,
    updateItemInventory,
    updateIsUseEInvoice,
    getOrderPeriodEInvoice,
    streamSettingLanguage,
    
};

export default FirebaseFirestoreService;
