import React, { FC } from 'react'
import { Dropdown, Grid, GridRow, Image } from 'semantic-ui-react'
import "./DropdownProfile.css"


interface PropsDropdownProfile {
  displayName: string,
  urlImage: string | null,
  logout: Function
}

const DropdownProfile: FC<PropsDropdownProfile> = (props) => {

  const options = [
    { key: 'user', text: 'Account', icon: 'user', value: 'user' },
    { key: 'settings', text: 'Settings', icon: 'settings', value: 'settings', disabled: true },
    { key: 'log_out', text: 'Sign Out', icon: 'sign out', value: 'log_out' },
  ]

  const handleSelection = (event: React.SyntheticEvent<HTMLElement, Event>, data: any) => {
    event.preventDefault();
    if (data.value === "log_out") {
      props.logout();
    }
  };

  const trigger = (
    <Grid columns={2}>
      <Grid.Row verticalAlign='middle' stretched only='tablet computer'>
        <span style={{ alignSelf: "center" }}>{props.displayName} &nbsp;&nbsp;&nbsp;&nbsp;</span>
        <Image avatar src={props.urlImage ? props.urlImage : 'https://react.semantic-ui.com/images/wireframe/image.png'} />
      </Grid.Row>
      <Grid.Row verticalAlign='middle' stretched only='mobile'>
        <Image avatar src={props.urlImage ? props.urlImage : 'https://react.semantic-ui.com/images/wireframe/image.png'} />
      </Grid.Row>
    </Grid>
  )
  return (
    <Dropdown
      trigger={trigger}
      options={options}
      pointing='top right'
      icon={null}
      onChange={handleSelection}
    />
  )
}

export default DropdownProfile