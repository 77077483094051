import {useEffect, useState} from "react";
import DropdownOps from "../model/dropdownOps";
import FirebaseFirestoreService from "../services/FirebaseFirestoreService";
import User from "../model/user";


export default function useRestaurant(user: User) {
    const [listRest, setListRest] = useState<DropdownOps[]>([]);

    useEffect(() => {
        if (user.permission === 0 || user?.restaurants?.length > 1) {

            const unsubscribe = FirebaseFirestoreService.streamRestaurantListItems(user,
                (querySnapshot: any) => {
                    const updatedGroceryItems = querySnapshot.docs.map((doc: any) => ({
                        key: doc.id,
                        text: doc.data().name,
                        value: doc.id,
                    }));
                    setListRest(updatedGroceryItems);
                },
                (error: any) => console.log(error)
            );
            return unsubscribe;
        }
    }, [setListRest]);

    return [listRest]
}