import React from "react";
import { defaultStaticRanges } from "react-date-range";

export const emptyItem = <>&nbsp;</>

export const vnStaticRanges = defaultStaticRanges.map((item: any) => {
    switch (item.label) {
        case 'Today':
            return { ...item, label: 'Hôm Nay' }
        case 'Yesterday':
            return { ...item, label: 'Hôm Qua' }
        case 'This Week':
            return { ...item, label: 'Tuần Này' }
        case 'Last Week':
            return { ...item, label: 'Tuần Trước' }
        case 'This Month':
            return { ...item, label: 'Tháng Này' }
        case 'Last Month':
            return { ...item, label: 'Tháng Trước' }
    }
});

export const jpStaticRanges = defaultStaticRanges.reduce((returnData: any, item: any) => {
    switch (item.label) {
        case 'Today':
            returnData.push({ ...item, label: '今日' })
            break
        case 'Yesterday':
            returnData.push({ ...item, label: '昨日' })
            break
        case 'This Week':
            returnData.push({ ...item, label: '今週' })
            break
        case 'This Month':
            returnData.push({ ...item, label: '今月' })
            break
    }
    return returnData
}, []);

export const listUnit = [{
    key: 'kg',
    text: 'kg',
    value: 'kg'
}, {
    key: 'gram',
    text: 'gram',
    value: 'gram'
}, {
    key: 'ml',
    text: 'ml',
    value: 'ml'
}, {
    key: 'lon',
    text: 'lon',
    value: 'lon'
}
];
export const ONTIME_REMAIN_DEFAULT = 10;
export const FIRST_BLOCK_TIME_DEFAULT = 60;
export const PASSWORD_LV2_DEFAULT = "123456";
export const TEXT_COLOR_DEFAULT = "#8A6025";
export const BACKGROUND_COLOR_DEFAULT = "#fafafa";
export const TOPPING_DEFAULT_MAX_QUANTITY = 1;
