import { createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateProfile } from "firebase/auth";
import { auth } from "../FireBaseConf";

const registerUser = async (email: string) => {
    const password = 'ppos2021';
    return await createUserWithEmailAndPassword(auth, email, password);
    // .then((userCredential) => {
    //     // Signed in 
    //     const user = userCredential.user;
    //     // ...
    // })
    // .catch((error) => {
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // ..
    // });
}

const loginUser = async (email: string, password: string) => {
    return await signInWithEmailAndPassword(auth, email, password);
}

const getProfile = async () => {
    const user = auth.currentUser;
    if (user !== null) {
        return {
            "displayName": user.displayName,
            "email": user.email,
            "photoURL": user.photoURL,
            "emailVerified": user.emailVerified,
            "uid": user.uid
        }
    }
    return null;
}

const updateProfileUser = async (displayName: string | null, photoURL: string | null, userCurrent?: any) => {
    const user = userCurrent ? userCurrent : auth.currentUser;
    if (user !== null) {
        return await updateProfile(user, { displayName: displayName, photoURL: photoURL })
    }
    return null;
}

const logoutUser = async () => {
    return await signOut(auth);
}

const changeState = (handler: any) => {
    return onAuthStateChanged(auth, (user) => {
        handler(user);
    });
}

const sendPasswordResetEmailUser = async (email: string) => {
    await sendPasswordResetEmail(auth, email);
}

const FirebaseAuthService = {
    registerUser,
    loginUser,
    logoutUser,
    changeState,
    sendPasswordResetEmailUser,
    getProfile,
    updateProfileUser
}

export default FirebaseAuthService;